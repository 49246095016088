import React from 'react';
import {
    Box, Typography, Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Layout, TopNavigation } from 'components';

export default function Info({ location }) {
    const seo = {
      title: "DOCUMENTARY+",
      description: `Home to the world’s best documentaries -- from Academy 
    Award winning classics and box office hits to festival darlings and cult favorites. 
    Nonfiction storytelling at its best, curated by the best.`,
    };
    const classes = useStyles();

    return (
        <Layout location={location} seo={seo}>
            <TopNavigation />
            <section className={classes.section} >
                <Box className={classes.box}>
                    <Typography
                        className={classes.text}
                        variant="body2">
                        For general inquiries:
                        <Link href="mailto:info@docplus.com"
                            className={classes.text}
                        >info@docplus.com</Link></Typography>

                </Box>
                <Box className={classes.box}>
                    <Typography
                        className={classes.text}
                        variant="body2">
                        For press inquiries: 
                        <Link href="mailto:amy@xtr.com"
                            className={classes.text}
                        >amy@xtr.com</Link></Typography>
                </Box>
            </section>
        </Layout>
    );
}

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        alignItems: 'center',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.between(0, 600)]: {
            height: '55vh'
        },
        height: '80vh'
    },
    text: {
        padding: 8
    }
}));
